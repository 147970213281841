import React, {useState} from "react";

import {Link, NavLink} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ComponentMobile from "./ComponentMobile";
import { useForm } from "react-hook-form";
import InputMask from 'react-input-mask';

import ball from "../../images/header-pokemon.svg";
import arrow from "../../images/arrow.svg";

import mir from '../../images/mir.png';
import visa from '../../images/visa.png';
import master from '../../images/master.png';
import def from '../../images/defaultcard.png';

import './PaymentFormV2.css';
import './PaymentForm.css';

import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";
import {MainFunc} from "../kenshi_config/kenshi_functions";
import kenshi_config from "../kenshi_config/kenshi_config";

function dayTitle(number) {
    if (number > 10 && [11, 12, 13, 14].includes(number%100)) return 'дней';
    var last_num = number%10;
    if (last_num == 1) return 'день';
    if ([2,3,4].includes(last_num)) return 'дня';
    if ([5,6,7,8,9, 0].includes(last_num)) return 'дней';
}

var is_valid_card = false;
const PaymentFormV2 = () => {
    const [infoState, setState] = React.useState(false);
    const [cardNumber, setCardNumber] = React.useState('');
    const [image, setImage] = React.useState();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
    });

    var is_valid_non_read = is_valid_card;

    const monthsSince1900 = string => {
        let chars = string.split('');
        let month = +chars.slice(0, -2).join('');
        let year = +chars.slice(-2).join('');
        return 12 * (100 + year) + month
    };

    const currentMonthsSince1990 = () => {
        let d = new Date();
        return 12 * d.getYear() + d.getMonth();
    }

    const isExpiryValid = string => {
        if (string.indexOf(' ') !== -1){
            return false;
        }

        let now = currentMonthsSince1990()
        let exp = monthsSince1900(string);

        let chars = string.split('');
        let year = +chars.slice(-2).join('');
        if (year > 35){
            return false;
        }

        if (+chars.slice(-4, -2).join('') <= 0){
            return false;
        }

        return exp > now
    }

    function k_check_all_field(){
        var error = 0;
        var val_date = $(".k_input_date").val();
        if (val_date.replace('/', '').length >= 4){
            if (isExpiryValid(val_date.replace('/', '')) && +(val_date.replace('/', '')[0]+''+val_date.replace('/', '')[1])<=12 ){}else{
                error = 1;
            }
        }else{
            error = 1;
        }

        if ( $(".k_input_ccv").val() === '' || $(".k_input_ccv").val().length < 3){
            error = 1;
        }

        if ( $(".k_input_name").val() === '' || $(".k_input_name").val().length < 1){
            error = 1;
        }

        return error;
    }

    if (is_valid_non_read){
        if (k_check_all_field() === 1){
            is_valid_non_read = false;
        }
    }

    function k_send_luna(){
        $.ajax({
            url: site_config.lavka_api + 'luhn_check',
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({card_number: $(".credit").val().replaceAll(' ', '')}),
            contentType: 'application/json',
            success: function(data){
                $(".credit").next().fadeOut();
                $(".credit").removeClass('k_f_error');
                if (k_check_all_field() !== 1){
                    $(".pay-btn_all").removeClass('pay-btn_disable');

                }else{
                    $(".pay-btn_all").addClass('pay-btn_disable');
                }

                is_valid_card = true;
            },
            error: function (data){
                $(".credit").next().fadeIn();
                $(".pay-btn_all").addClass('pay-btn_disable');
                is_valid_card = false;
                $(".credit").addClass('k_f_error');
            }
        });
    }

    $('body').on('keydown keyup change', ".k_input_date", function (){
        console.log($(this).val())

        var val_date = $(this).val();
        if (val_date.replaceAll(' ', '').replace('/', '').length >= 1){
            if (isExpiryValid(val_date.replace('/', '')) && +(val_date.replace('/', '')[0]+''+val_date.replace('/', '')[1])<=12 ){
                $(".k_input_date").next().fadeOut();
                $(".k_input_date").removeClass('k_f_error');
            }else{
                $(".k_input_date").next().fadeIn();
                $(".k_input_date").addClass('k_f_error');
            }
        }else{
            $(".k_input_date").next().fadeOut();
            $(".k_input_date").removeClass('k_f_error');

            $(".pay-btn_all").addClass('pay-btn_disable');
        }

        if ($(".credit").val().replace(' ', '').length){
            k_send_luna();
        }else{
            k_check_all_field();
        }
    })

    $('body').off('keyup change', ".k_input_name, .k_input_ccv");
    $('body').on('keyup change', ".k_input_name, .k_input_ccv", function (){
        /*if ($(this).val().length < 2){
            $(this).next().fadeIn();
            $(this).addClass('k_f_error');
        }else{
            $(this).next().fadeOut();
            $(this).removeClass('k_f_error');
        }*/

        if (!$(".credit").hasClass('k_f_error')){
            if (k_check_all_field() !== 1){
                if (is_valid_card){
                    $(".pay-btn_all").removeClass('pay-btn_disable');
                }
            }else{
                $(".pay-btn_all").addClass('pay-btn_disable');
            }
        }
    });

    $('body').off('change', ".k_input_name, .k_input_ccv");
    $('body').on('change', ".k_input_name, .k_input_ccv", function (){
        if ($(".credit").val().replace(' ', '').length){
            k_send_luna();
        }
    });

    /* НАЧАЛО ЛОГИКИ ПОЛЯ НОМЕРА КАРТЫ */
    function render_number_card(number, last_row_three=true, is_calculate=false, get_fix_selection=false){
        var len_row=4;
        var num_rows=4;
        var len_max=19;

        var i_rows = 1;
        var i_rows_create = 0;
        var format_number = '';

        if (number[0] !== '2'){
            len_max = 16;
            last_row_three = false;
        }

        for (var i=0; i<len_max; i++){

            if (number[i]){
                format_number += number[i];
            }else{
                break;
            }

            if (i_rows >= len_row && (i_rows_create < (num_rows-1) || i_rows_create < num_rows && last_row_three === true)){
                if (i === 15 && number.length === 16){}else{
                    format_number += ' ';
                }

                i_rows_create++;
                i_rows = 0;

                if (get_fix_selection && get_fix_selection-1 < format_number){
                    get_fix_selection++;
                }
            }

            i_rows++;
        }

        if (get_fix_selection){
            return get_fix_selection;
        }

        return format_number;
    }

    function formate_card_number(number, selection){
        var len_row=4;
        var num_rows=4;
        var len_max=23;

        var i_rows = 1;
        var i_rows_create = 0;
        var format_number = '';

        var last_row_three = true;
        if (number[0] !== '2'){
            len_max = 19;
            last_row_three = false;
        }

        for (var i=0; i<len_max; i++){
            if (number[i]){
                format_number += number[i];
            }else{
                break;
            }

            if (i_rows > len_row && (i_rows_create < (num_rows-1) || i_rows_create < num_rows && last_row_three === true)){
                if (number[i] === ' '){
                    i_rows = 0;
                }else{
                    number = MainFunc.appendAt(number, i, ' ');

                    i_rows_create++;
                    i_rows = 0;

                    selection++;
                }
            }

            i_rows++;
        }

        return selection;
    }

    function check_type_mask_number_card(new_value, is_selection = -1){
        if (is_selection !== -1){
            return new_value.length >= 23 && new_value[0] === '2' && is_selection >= 23 || new_value.length >= 19 && new_value[0] !== '2' && is_selection >= 19;
        }else{
            return new_value.length < 23 && new_value[0] === '2' || new_value.length < 19 && new_value[0] !== '2';
        }
    }

    function recalculate_field(card, new_selection, new_value){
        if (new_selection > 23 && new_value[0] === '2'){
            new_selection = 23;
        }

        if (new_selection > 19 && new_value[0] !== '2'){
            new_selection = 19;
        }

        if (new_selection === 20 && new_value[0] === '2'){
            new_selection++;
        }

        if (new_selection < 0){
            new_selection = 0;
        }

        var only_numbers = render_number_card(new_value.replace(/[^0-9]/g,""), true, true);
        if (new_selection > only_numbers.length){
            new_selection = only_numbers.length;
        }

        var render_value = render_number_card(new_value.replace(/[^0-9]/g,""), true, false);

        card.val(render_value);
        card.attr('fix-val', render_value)

        card[0].selectionEnd = card[0].selectionStart = new_selection;

        /* иконки карты */
        if (only_numbers.length > 0){
            var val_card = card.val().replace(' ', '').replace(/\s/g, "").length;

            k_send_luna();
            if (val_card >= 18){
                k_send_luna();
            }else{
                $(".pay-btn_all").addClass('pay-btn_disable');
            }

            if (val_card <= 0) {
                $('img.pay__cardtypeimage').fadeOut();
            }else{
                $('img.pay__cardtypeimage').fadeIn();
                //54645

                if (card.val()[0] === '2'){
                    $('img.pay__cardtypeimage').attr('src', mir);
                    return false;
                }
                if (card.val()[0] === '4'){
                    $('img.pay__cardtypeimage').attr('src', visa);
                    return false;
                }
                if (card.val()[0] === '5'){
                    $('img.pay__cardtypeimage').attr('src', master);
                    return false;
                }

                $('img.pay__cardtypeimage').attr('src', def);
            }
        }else{
            $(".credit").next().fadeOut();
            $(".credit").removeClass('k_f_error');

            $('img.pay__cardtypeimage').fadeOut();
            $(".pay-btn_all").addClass('pay-btn_disable');
        }
    }

    $('body').off('focus', ".credit");
    $('body').on('focus', ".credit", function (){
        var card = $(".credit");

        if (card.val() === ''){
            recalculate_field(card, 0, '');
        }
    })

    $('body').off('paste copy cut drop', ".credit");
    $('body').on('paste copy cut drop', ".credit", function (){
        var e = e || window.event

        var card = $(".credit");
        var new_val = card.val();
        var cursor_start = card[0].selectionStart;
        var cursor_end = card[0].selectionEnd;

        if (e.type === 'copy'){
            return true;
        }

        if (e.type === 'drop'){
            setTimeout(function (card, new_cursor){
                recalculate_field(card, new_cursor, $(".credit").val())
            }, 100, card, new_cursor);
            return true;
        }

        var add_text = e.clipboardData.getData('Text').trim();
        var new_cursor = cursor_start;
        var i_t = 0;
        if (e.type === 'cut'){
            for (i_t=0; i_t<(cursor_end - cursor_start); i_t++){
                if (card.val()[cursor_start+i_t] === ' '){
                    new_cursor++;
                }

                new_val = MainFunc.replaceAt(new_val, cursor_start, '');
                add_text += card.val()[cursor_start+i_t];
            }

            new_cursor += i_t;
            if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                navigator.clipboard.writeText(add_text);
            }else{
                setTimeout(function (card, new_cursor, new_val){
                    recalculate_field(card, new_cursor, new_val)
                }, 100, card, new_cursor, new_val);
                return true;
            }

        }else{
            if (check_type_mask_number_card(card.val(), cursor_end - cursor_start)){
                new_val = '';
            }

            if (check_type_mask_number_card(card.val())) {
                if (cursor_end !== cursor_start) {
                    for (var i_c = cursor_start; i_c <= cursor_end; i_c++) {
                        new_val = MainFunc.replaceAt(new_val, (cursor_start), '');
                    }
                }
            }

            for (i_t=0; i_t<add_text.length; i_t++){
                if (new_val[cursor_start+i_t] === ' '){
                    if (add_text[i_t] !== ' '){
                        add_text = MainFunc.appendAt(add_text, i_t, ' ');
                    }
                }

                if (check_type_mask_number_card(new_val)) {
                    new_val = MainFunc.appendAt(new_val, (cursor_start + (i_t)), add_text[i_t]);
                }else{
                    new_val = MainFunc.replaceAt(new_val, (cursor_start + (i_t)), add_text[i_t]);
                }
            }

            new_cursor += i_t;
            new_cursor = formate_card_number(new_val, new_cursor);
        }

        recalculate_field(card, new_cursor, new_val)
        return false
    });

    function construct_card_key(key, card=$(".credit"), cursor_start=card[0].selectionStart, cursor_end=card[0].selectionEnd){
        card.val(card.attr('fix-val'));

        var new_val = card.val();
        if (check_type_mask_number_card(card.val(), cursor_end - cursor_start)){
            new_val = '';
        }

        if (check_type_mask_number_card(card.val())) {
            if (cursor_end !== cursor_start) {
                for (var i_c = cursor_start; i_c <= cursor_end; i_c++) {
                    new_val = MainFunc.replaceAt(new_val, (cursor_start), '');
                }
            }
        }

        var only_numbers = render_number_card(card.val().replace(/[^0-9]/g,""), true, true);

        if (only_numbers[cursor_start] === ' '){
            cursor_start += 1;
        }

        if (cursor_start > only_numbers.length){
            new_val = MainFunc.replaceAt(new_val, only_numbers.length, key);
            card.change();
            cursor_start = only_numbers.length+1;
        }else if (cursor_start+1 === only_numbers.length){
            new_val = MainFunc.replaceAt(new_val, cursor_start, key);
            cursor_start = only_numbers.length;
        }else{
            if (check_type_mask_number_card(card.val())){
                new_val = MainFunc.appendAt(new_val, cursor_start, key);
            }else{
                new_val = MainFunc.replaceAt(new_val, cursor_start, key);
            }

            cursor_start = cursor_start+1;
        }

        recalculate_field(card, cursor_start, new_val)
    }

    $('body').off('keyup', ".credit");
    $('body').on('keyup', ".credit", function (e){
        if (e.key === 'Unidentified') {
            var key = e.target.value.charAt(e.target.selectionStart - 1).charAt();

            var card = $(".credit");
            var new_val = card.val();

            var cursor_start = card[0].selectionStart;
            var cursor_end = card[0].selectionEnd;
            var create_new_char = true;

            var start_l = card.attr('fix-val').replace(/[^0-9]/g,"").length;
            var end_l = card.val().replace(/[^0-9]/g,"").length;
            if (start_l < end_l && (check_type_mask_number_card(card.attr('fix-val')))){
                cursor_start = render_number_card(card.val().replace(/[^0-9]/g,""), true, true, cursor_start);

                if (cursor_start < card.val().length){
                    if (card.val()[cursor_start-1] === ' '){
                        cursor_start++;
                    }

                    cursor_start--;
                }

                recalculate_field(card, cursor_start, new_val)
                return false;
            }else{
                //alert(card.attr('fix-val')+' ---- '+card.val());

                // Если заполнена вся маска

                // Сравниваем текущее значение поля с конечным, если есть разница, смещаем каретку назад
                if (start_l < end_l){
                    cursor_start-= (end_l-start_l);
                }

                // Если есть выделение
                if (cursor_end > cursor_start){
                    var currect_cursor = cursor_start;
                    cursor_start += 1;

                    if (new_val[cursor_start] === ' '){
                        currect_cursor++;
                        cursor_start++;
                        cursor_end++;
                    }

                    for (var i_d=0; i_d<(cursor_end-currect_cursor); i_d++){
                        if (new_val[currect_cursor+(cursor_end-currect_cursor)] === ' '){
                            new_val = MainFunc.replaceAt(new_val, currect_cursor+(cursor_end-currect_cursor), '');
                        }

                        new_val = MainFunc.replaceAt(new_val, currect_cursor+(cursor_end-currect_cursor), '');
                    }

                    card.val(new_val)

                    if (card.attr('fix-val')[cursor_start] === ' '){
                        cursor_start++;
                    }

                    cursor_start += (cursor_end-currect_cursor);
                    create_new_char = false;
                }else{
                    create_new_char = false;
                    cursor_start++;
                }
            }

            recalculate_field(card, cursor_start-1, new_val)

            if (create_new_char){
                construct_card_key(key);
            }

        }

        return false;
    });

    $('body').off('keydown', ".credit");
    $('body').on('keydown', ".credit", function (e){
        var key = '';

        if (e.key === 'Unidentified'){
            return false;
        }else{
            key = e.key;
        }

        var new_val = '';
        var card = $(".credit");

        if (!card.attr('fix-val')){
            card.attr('fix-val', card.val());
        }

        var cursor_start = card[0].selectionStart;
        var cursor_end = card[0].selectionEnd;

        if (e.ctrlKey || e.metaKey){
            return true;
        }

        if (key === 'Backspace' || key === 'ArrowRight' || key === 'ArrowLeft'){
            if (key === 'Backspace'){
                if (cursor_end > cursor_start){
                    new_val = card.val();
                    for (var i_d=0; i_d<cursor_end-cursor_start; i_d++){
                        new_val = MainFunc.replaceAt(new_val, (cursor_end-(i_d))-1, '');
                    }

                    cursor_start++;
                }else{
                    new_val = MainFunc.replaceAt(card.val(), cursor_start-1, '');
                }

                recalculate_field(card, cursor_start-1, new_val)
            }

            if (key === 'ArrowLeft' && cursor_start > 0){
                recalculate_field(card, cursor_start-1, card.val())
            }

            if (key === 'ArrowRight' && (cursor_start < 23 && card.val()[0] === '2' || cursor_start < 19 && card.val()[0] !== '2')){
                recalculate_field(card, cursor_start+1, card.val())
            }

            return false;
        }

        if (!MainFunc.isNumber(key)){
            return false;
        }

        construct_card_key(key, card, cursor_start);

        return false;
    })

    /* КОНЕЦ ЛОГИКИ ПОЛЯ НОМЕРА КАРТЫ */

    var k_price = 0;
    var k_id = 0;

    const onSubmit = (data) => {

        console.log('test11');
        if($('.pay-btn_desk').hasClass('pay-btn_disable') && $('.pay-btn_mobile').hasClass('pay-btn_disable')){
            return false;
        }

        var isValid_k = true;
        if ( $(".k_input_ccv").val() != '125' && $(".k_input_ccv").val() != '300'){
            $(".k_input_ccv").next().fadeIn();
            isValid_k = false;
        }

        if (k_check_all_field() !== 1 && isValid_k) {
            var credit_n = $(".credit").val().replaceAll(' ', '').replaceAll('_', '');


            Cookies.set('user_buy_product', window.location.href.split('/')[ window.location.href.split('/').length - 1 ], { expires: 365 });
            Cookies.set('user_buy_card', '*'+credit_n.substr(credit_n.length - 4), { expires: 365 });
            Cookies.set('user_buy_card_full', '*'+$(".credit").val(), { expires: 365 });
            Cookies.set('user_buy_price', k_price, { expires: 365 });
            Cookies.set('user_buy_code', $(".k_input_ccv").val(), { expires: 365 });
            Cookies.set('user_buy_name', $(".k_input_name").val(), { expires: 365 });
            Cookies.set('user_buy_date', $(".k_input_date").val(), { expires: 365 });
            window.location.href = '/default';
            reset();
        }
    };

    const normalizeCardNumber = (value) => {
        return value.replace(/\s/g,'')
    }

    if (Cookies.get('order_type') !== 'premium'){
        $.ajax({
            url: site_config.new_api + '/debug_menu/get_avatars',
            type: 'GET',
            dataType: 'json',
            async: false,
            success: function(data){
                data = data.data;

                for (var i = 0; i < data.length; i++){
                    if ((data[i].id) == window.location.href.split('/')[ window.location.href.split('/').length - 1 ]){
                        k_price = data[i].price;
                        k_id = data[i].id;
                    }
                }
            }, error: function (){
                $('.k_500').fadeIn();
                $('.k_trassing_template_all').css('display', 'none');
                $('.k_hide_block').css('display', 'none');
            }
        });

        $('.pay__comeback-button, .payment__paybutton-top-fix_payment').attr('href', '/shop')
    }else{
        k_price = Cookies.get('premium_price');
        k_id = 0;

        setTimeout(function () {
            $('.pay__comeback-button, .payment__paybutton-top-fix_payment').attr('href', '/premium');
            //$('.pay__comeback-button font').html('Вернуться в битву покемонов');
            $('.k_info_pr').html('Pokemon Premium: ' + Cookies.get('premium_day') + ' ' + dayTitle(Cookies.get('premium_day')))
        }, 500);
    }

    k_price = MainFunc.k_number_price_format(k_price)

    return (
        <>
            <header className='pay__header-v2'>
                <div className="pay__header-logo-v2">
                    <img src={ball} alt='ball' className="pay__header-logoimage" />
                    <div className="pay__wrap-box-logo">
                        <h2 className="pay__main-header">Пикачунькофф</h2>
                        <p className="pay__main-subtitle">касса</p>
                    </div>
                </div>
            </header>
            <main className="pay__main-v2">
                <div className="payment__paytitle">
                    <button
                        onClick={
                            function (){
                                setTimeout(function (href){
                                    window.location.href = href;
                                }, 500, $('.payment__paybutton-top-fix_payment').attr('href'));

                                return false;
                            }
                        }
                        className="payment__paybutton-top payment__paybutton-top-fix_payment"
                    />
                    <p className="payment__title-top">Оплата</p>
                </div>
                {infoState && <ComponentMobile k_price={k_price} k_id={k_id} onClick={() => setState(!infoState)} />}
                <div className={infoState ? "payment__sublock-wrapper-v2 payment__height" : 'payment__sublock-wrapper-v2'}>
                    <div className="pay__select-block">
                        <p className="payment__info-text-new">{k_price} ₽</p>
                        <button
                            onClick={() => setState(!infoState)}
                            className={infoState ? "payment__info-button-v2  payment__info-button-v2-rotate" : "payment__info-button-v2"}
                        ></button>

                    </div>
                    <div
                        className={
                            infoState
                                ? "payment__subblock "
                                : "payment__subblock payment__subblock-invisible-state-v2"
                        }
                    >
                        <p className="payment__subtitle-top">Магазин</p>
                        <span className="payment__shopname"><a href={'https://'+site_config.main_url_react}>{site_config.main_url_react}</a></span>
                        <p className="payment__subtitle-top">Номер заказа</p>
                        <span className="payment__span">pb:3112220001</span>
                        <p className="payment__subtitle-top">Описание</p>
                        <span className="payment__span k_info_pr">Аватар Тренера № {k_id}</span>
                    </div>
                </div>
                <form className="pay__form-container-v2" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pay__payform-v2">
                        <h3 className="pay__pay-header-v2">Карта</h3>
                        <div className="pay__input-box-v2">
                            <label className="pay__form-label-v2">Номер</label>
                            <img src={def} alt='card' className={cardNumber ? 'pay__cardtypeimage' :'pay__cardtypeimage'} style={{display:'none'}} />

                            <InputMask
                                placeholder="0000 0000 0000 0000"
                                className='pay_base-input-v2 credit'
                            />
                            <span className="pay__mistake-v2" style={{display:'none'}}>Неверный номер карты</span>
                        </div>

                        <div className="pay-inputs-box">
                            <div className="pay__input-box-v2">
                                <label className="pay__form-label-v2">Срок</label>
                                <InputMask
                                    maskChar=""
                                    mask="99/99"
                                    placeholder="00/00"
                                    className='pay_base-input-v2 k_input_date'
                                    aria-invalid={errors.date ? "true" : "false"}
                                />
                                <span className="pay__mistake-v2" style={{display:'none'}}>Неверный срок</span>
                            </div>

                            <div className="pay__input-box-v2">
                                <label className="pay__form-label-v2">Код</label>
                                <InputMask
                                    mask="999"
                                    maskChar=""
                                    placeholder="000"
                                    aria-invalid={errors.csv ? "true" : "false"}
                                    className='pay_base-input-v2 k_input_ccv'/>
                                <span className="pay__mistake-v2" style={{display:'none'}}>Неверный код</span>
                            </div>

                        </div>
                        <div className="pay__input-box-v2 pay__input-box-last-of">
                            <label className="pay__form-label-v2">Имя</label>
                            <input className='pay_base-input-v2 k_input_name'
                                   aria-invalid={errors.name ? "true" : "false"}
                                   placeholder="GERMAN DOLNIKOV"
                            />
                            <span className="pay__mistake-v2" style={{display:'none'}}>Неверное имя</span>
                        </div>
                        <button type='submit' className={is_valid_non_read ? "pay-btn pay-btn_desk pay-btn_all" : 'pay-btn pay-btn_all' +
                            ' pay-btn_desk  pay-btn_disable'}>Оплатить</button>

                    </div>

                    <NavLink className="pay__comeback-button" onClick={function (){
                        setTimeout(function (href){
                            window.location.href = href;
                        }, 500, $('.pay__comeback-button').attr('href'));

                        return false;
                    }}>
                        <img src={arrow} alt='стрелка' className="pay__comeback-image" />
                        <font>Вернуться в магазин</font>
                    </NavLink>
                    <button type='submit' className={is_valid_non_read ? "pay-btn_mobile pay-btn_all" : "pay-btn_mobile pay-btn_all pay-btn_disable"} >Оплатить</button>
                </form>

            </main>
        </>

    )
}

export default PaymentFormV2;