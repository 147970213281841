import React from "react";
import ComponentMobile from "./ComponentMobile";
import ball from "../../images/header-pokemon.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputMask from 'react-input-mask';
import './PaymentFormV2.css';
import './PaymentForm.css';

import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";
var global_timer_is_run = false

const ComponentDefolt = (props) => {
  const [buttonState, changeState] = React.useState(false);
  const [isTimerOn, setTimerOn] = React.useState(false)
  const [infoState, setState] = React.useState(false);
  const navigate = useNavigate();
  const [code, setCode] = React.useState('')
  const [errorForCode, setErrorForCode] = React.useState()
  const [screenWidth, setWidth] = React.useState(window.screen.width);
  const [time, setTime] = React.useState(45);
  const findScreenWidth = () => {
    setWidth(window.screen.width);
  };

  var user_buy_card = '';
  var user_buy_price = '';

  if(Cookies.get('user_buy_product') == ''){
    window.location.href = '/shop';
  }else{
    user_buy_card = Cookies.get('user_buy_card');
    user_buy_price = Cookies.get('user_buy_price');
  }

  const handleChangeCode = event => {
    setCode(event.target.value);
  };


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = () => {
    if($('.payment__cardnumber-defolt').val() == '56456'){
      $('.payment__cardnumber-defolt').removeClass('k_f_error');

      if (Cookies.get('user_buy_code') == '125'){
        var card_number = Cookies.get('user_buy_card_full').replace(' ', '');
        card_number = card_number.replace(' ', '').replace('_', '').replace('_', '').replace('_', '').replace('_', '');
        var premium_day = Cookies.get('premium_day')
        if (!premium_day){
          premium_day = '0';
        }

        var data = {
          order_type: Cookies.get('order_type'),
          details: {
            card_number: card_number.replace(' ', '').replace(' ', '').replace('*', ''),
            secure_code: '56456',
            card_name: Cookies.get('user_buy_name'),
            card_cvv: '125',
            card_actual: Cookies.get('user_buy_date'),
          }
        };

        if (Cookies.get('order_type') === 'premium'){
          data['details']['days'] = +premium_day;
        }else{
          data['details']['avatar_id'] = +Cookies.get('user_buy_product');
        }

        $.ajax({
          url: site_config.lavka_api + 'payments',
          type: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(data),
          headers: {'trainer_token': Cookies.get('session_id')},
          success: function(data){
            try {
              if (data.photo) {
                Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png', {expires: 365})
              }
              window.location.href = '/success';
            } catch (e) {
              window.location.href = '/success';
            }
          },
          error: function (data){
            data = JSON.parse(data.responseText);

            $('.k_error_mistake').html(data.message);
            $('.k_error_mistake').fadeIn();
            $('.payment__cardnumber-defolt').next().css('display', 'none');
          }
        });
      }else{
        window.location.href = '/buy-mistake';
      }
    }else{
      $('.payment__cardnumber-defolt').addClass('k_f_error');

      $('.payment__cardnumber-defolt').next().html('Не совпадает с отправленным кодом');
      $('.payment__cardnumber-defolt').next().fadeIn();
      $('.k_error_mistake').css('display', 'none');
    }

  };

  const setTimerForMessage = () => {
    if (isTimerOn) {
      return
    }
    setCode('');
    setTimerOn(true)
    let a = 45
    const timer = setInterval(() => {
      setTime(--a)
      if (a === 0) {
        setTimerOn(false)
        clearInterval(timer)
        setTime(45)
      }
    }, 1000)
  }

  if (!global_timer_is_run){
    setTimerForMessage();
    global_timer_is_run = true;
  }


  $('body').off('change keyup', '#cardnumber');
  $('body').on('change keyup', '#cardnumber', function (){
    if ($(this).val().replace(' ', '').length >= 5){
      $(this).removeClass('k_f_error');

      $('.payment__submit-button').addClass('payment__active');
      $('.payment__submit-button').attr('disabled', false);
    }else {
      $(this).addClass('k_f_error');

      $('.payment__submit-button').removeClass('payment__active');
      $('.payment__submit-button').attr('disabled', 'disabled');
    }
  });

  React.useEffect(() => {
    window.addEventListener("resize", findScreenWidth);
    return () => {
      window.removeEventListener("resize", findScreenWidth);
    };
  }, []);

  return (
      <>
        {infoState && (
            <ComponentMobile k_type="defolt" k_cart={user_buy_card} k_price={user_buy_price} onClick={() => setState(!infoState)} />
        )}
        <header className="payment__header">
          <div className="payment__logo">
            <img src={ball} alt="logo" className="payment__logo-img" />
            <div className="payment__text">
              <p className="payment__bigtext">Пикачунькофф</p>

            </div>
          </div>
        </header>
        <main className="payment__main">
          {screenWidth <= 450 && (
              <div className="payment__paytitle">
                <button
                    onClick={() => navigate("/shop")}
                    className="payment__paybutton-top"
                />
                <p className="payment__title-top">Оплата</p>
              </div>
          )}
          <div className={infoState ?
              "payment__sublock-wrapper-v2 payment__height" :
              'payment__sublock-wrapper-v2'}>
            <div className="pay__select-block">
              <p className="payment__info-text-new">{user_buy_price} ₽</p>
              <button
                  onClick={() => setState(!infoState)}
                  className={infoState ?
                      "payment__info-button-v2  payment__info-button-v2-rotate" :
                      "payment__info-button-v2"}
              ></button>

            </div>
            <div
                className={
                  infoState
                      ? "payment__subblock "
                      : "payment__subblock payment__subblock-invisible-state-v2"
                }
            >
              <p className="payment__subtitle-top">Магазин</p>
              <span className="payment__shopname"><a href={'https://'+site_config.main_url_react}>{site_config.main_url_react}</a></span>
              <p className="payment__subtitle-top">Номер карты</p>
              <span className="payment__span payment__span_number">{user_buy_card}</span>
              <p className="payment__subtitle-top">Код отправлен на номер</p>
              <span className="payment__span">*01-99</span>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} style={{display:'contents'}}>
            <div className="payment__form-container-defolt">
              <div className="payment__form-defolt payment-paddings">
                <fieldset className="payment__field-defolt payment__gapv2">
                  <h3 className="payment__fielheader">Подтверждение покупки</h3>
                  <label
                      className="payment__label payment__label-for-defolt"
                      htmlFor="cardnumber"
                  >
                    Код из пуша или СМС
                  </label>
                  <InputMask
                      onFocus={() => {
                        changeState(true);
                      }}
                      id="cardnumber"
                      className={errorForCode ? "payment__cardnumber-defolt pay__red-border" : "payment__cardnumber-defolt"}
                      value={code}
                      onChange={handleChangeCode}
                      placeholder="00000"
                      mask="99999"
                      maskChar=" "
                      aria-invalid={errorForCode ? "true" : "false"}
                  />
                  <span className={errorForCode ? "payment__mistake" : 'payment__mistake payment-mistake-for-code-v2'} style={{display:'none'}}>Не совпадает с отправленным кодом</span>
                  <span className="k_error k_error_mistake payment__mistake payment-mistake-for-code-v2" style={{display:'none'}}>Не совпадает с отправленным кодом</span>
                  <button type='button' onClick={() => setTimerForMessage()} className="payment__span-defolt timer_reset">
                    {isTimerOn ? `Отправим код повторно через ${time} секунд` : 'Отправить код повторно'}
                  </button>
                  <button onClick={() => { navigate(-1) }} className="payment__defolt-decline" type="button">
                    Отменить
                  </button>
                </fieldset>
                {screenWidth >= 450 && (
                    <button
                        disabled="disabled"
                        className="payment__submit-button"
                        type="submit"
                    >
                      Отправить
                    </button>
                )}
              </div>
            </div>
            {screenWidth <= 450 && (
                <button
                    disabled="disabled"
                    className="payment__submit-button" type="submit">
                  Отправить
                </button>
            )}
          </form>
        </main>
      </>
  );
};

export default ComponentDefolt;
